import type { FunctionComponent } from '@/common/types';

export function SuccessIcon(): FunctionComponent {
  return (
    <svg width='128' height='127' viewBox='0 0 128 127' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.2'
        d='M64 126.416C98.9088 126.416 127.208 98.1168 127.208 63.208C127.208 28.2992 98.9088 0 64 0C29.0912 0 0.791992 28.2992 0.791992 63.208C0.791992 98.1168 29.0912 126.416 64 126.416Z'
        fill='#D3C1E5'
      />
      <path
        d='M64.0005 114.103C92.109 114.103 114.895 91.316 114.895 63.2075C114.895 35.099 92.109 12.3125 64.0005 12.3125C35.8919 12.3125 13.1055 35.099 13.1055 63.2075C13.1055 91.316 35.8919 114.103 64.0005 114.103Z'
        fill='#7833B3'
      />
      <path
        d='M58.064 84.9687L40.127 61.9077L50.558 53.7947L59.05 64.7137L87.739 34.4297L97.3329 43.5177L58.064 84.9687Z'
        fill='white'
      />
    </svg>
  );
}
